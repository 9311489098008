import request from '@/utils/request'
//人员列表
/* export function getUserList(page) {
    return request({
        url: '/admin/user/list/'+page,
        method: 'get'
    })
} */
export function getUserList(page,data) {
    return request({
        url: '/admin/user/list/' + page,
        method: 'post',
        data
    })
}
// 获取人员属性
export function getUserStruct() {
    return request({
        url: '/admin/common/list',
        method: 'get'
    })

}
// 人员详细信息
export function getUserInfo(id) {
    return request({
        url: '/admin/user/'+id,
        method: 'get',
    })
}


//新增用户
export function AddUser(data) {
    return request({
        url: '/admin/user',
        method: 'post',
        data
    })
}
//更新用户
export function UpdateUser(data) {
    return request({
        url: '/admin/user/' + data.id,
        method: 'PUT',
        data
    })
}
//删除用户
export function DeleteUser(id) {
    return request({
        url: '/admin/user/'+id,
        method: 'DELETE',
    })
}

//岗位信息查询
export function SelectJobHistory(data,pageid){
    return request({
        url: '/admin/user/job/' + pageid,
        method:'POST',
        data
    })
}
/////////////////planB////////////////////
export function getReserveList(page, data) {
    return request({
        url: '/admin/user/reserve/list/' + page,
        method: 'post',
        data
    })
}
//新增用户
export function AddReserveUser(data) {
    return request({
        url: '/admin/user/reserve/store',
        method: 'post',
        data
    })
}