<template>
  <el-dialog v-model="centerDialogVisible" :title="dialogTitle" width="40%" :close-on-click-modal="true">

    <el-descriptions title="" direction="vertical" :column="3" size="small" border>
      <el-descriptions-item label="姓名">{{info.name}}</el-descriptions-item>
      <el-descriptions-item label="联系方式">{{ info.phone}}</el-descriptions-item>
      <el-descriptions-item label="身份证">{{ info.idcard}}</el-descriptions-item>
      <el-descriptions-item label="民族">{{ info.ethnic_group }}</el-descriptions-item>
      <el-descriptions-item label="入职时间">
        <el-tag size="small" type="warning">备用人员</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="性别">{{info.sex== 1 ? '男' : '女'}}</el-descriptions-item>
      <el-descriptions-item label="籍贯">{{info.native_place}}</el-descriptions-item>
      <el-descriptions-item label="现住址" :span="2">{{info.residence_address}}</el-descriptions-item>
      <el-descriptions-item label="备注">{{info.planb.note}}</el-descriptions-item>
    </el-descriptions>

  </el-dialog>
</template>

<script setup>
import {defineExpose,ref,} from "vue";


const info = ref()

//是否显示Dialog
let centerDialogVisible = ref(false);

//初始化Dialog标题
let dialogTitle = ref("");
//编辑框相关
const showModal = (insertdata) => {
  info.value  = insertdata
    dialogTitle.value = `${insertdata.name}的个人信息`;
    centerDialogVisible.value = true
    };




defineExpose({
  showModal,
});

</script>




<style lang="scss" scoped>

</style>